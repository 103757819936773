import React from 'react'

const Masterplan = () => {
    return(
        <div className="section text-center" id="masterplan">
            <div className="embed-responsive-vt">
                <iframe className="embed-responsive-item-vt" title='masterplan' src="https://www.lanube360.com/fundozapallar360/"></iframe>
            </div>
        </div>
    )
}
export default Masterplan