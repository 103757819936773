import React from "react";
import { PageView, initGA } from '../../components/Tracking'
// core components
import NavbarProyecto from '../../components/NavbarProyecto'
import HeaderProyecto from '../../components/HeaderProyecto'
import ElProyecto from '../../components/ElProyecto'
import Masterplan from '../../components/Masterplan'
import Galeria from '../../components/Galeria'
import Ubicacion from '../../components/Ubicacion'
import Contacto from '../../components/Contacto'

function HomePage() {
  document.documentElement.classList.remove("nav-open");
  initGA('UA-155745089-1')
  PageView()

  React.useEffect(() => {
    document.body.classList.add("section-page");
    
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
	import('react-facebook-pixel')
		.then((x) => x.default)
		.then((ReactPixel) => {
		ReactPixel.init('310992390975113') // facebookPixelId
		ReactPixel.pageView()
	})
    return function cleanup() {
      document.body.classList.remove("section-page");
    };
  });
  return (
    <>
      <NavbarProyecto/>
      <HeaderProyecto/>
      <ElProyecto/>
      <Masterplan/>
      <Galeria/>
      <Ubicacion/>
      <Contacto/>
    </>
  );
}

export default HomePage