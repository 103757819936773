import React from "react";
import { Link } from 'react-router-dom'
// reactstrap components
import { Container, Row, Col, Carousel, CarouselItem, CarouselIndicators, Button} from "reactstrap";

// core components
const items = [
    {
      src: "url(" + require("assets/img/sections/v2.jpg") + ")",
      content: (
        <Container>
          <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Fundo Zapallar</h1>
              <h5>Vista majestuosa entre Cachagua y Zapallar</h5>
              <br />
              <div className="buttons">
                <Button
                  className="btn"
                  color="danger"
                  to="/#informacion"
                  tag={Link}
                  size="sm"
                >
                  <i className="nc-icon nc-ctrl-down x3" />
                </Button>
                
              </div>
            </Col>
          </Row>
        </Container>
      ),
      altText: "",
      caption: ""
    },
    {
      src: "url(" + require("assets/img/sections/v1.jpg") + ")",
      content: (
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Fundo Zapallar</h1>
              <h5>Arquitectura tradicional que caracteriza a Zapallar al estilo de la Riviera Italiana.</h5>
              <br />
              <div className="buttons">
                <Button
                  className="btn"
                  color="danger"
                  to="/#informacion"
                  tag={Link}
                  size="sm"
                >
                  <i className="nc-icon nc-ctrl-down x3" />
                </Button>
                
              </div>
            </Col>
          </Row>
        </Container>
      ),
      altText: "",
      caption: ""
    },
    {
      src: "url(" + require("assets/img/sections/v3.jpg") + ")",
      content: (
        <Container>
          <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Fundo Zapallar</h1>
              <h5>
                Uno de los barrios más elegantes del borde costero.
              </h5>
              <br />
              <div className="buttons">
                <Button
                  className="btn"
                  color="danger"
                  to="/#informacion"
                  tag={Link}
                  size="sm"
                >
                  <i className="nc-icon nc-ctrl-down x3" />
                </Button>
                
              </div>
            </Col>
          </Row>
        </Container>
      ),
      altText: "",
      caption: ""
    }
  ];
const HeaderProyecto = () => {
        // carousel - header 3
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = newIndex => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
    return(
      <div className="header-3" id="header">
        <div className="page-carousel">
          <div className="filter" />
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {items.map(item => {
                return (
                  <CarouselItem
                    onExiting={onExiting}
                    onExited={onExited}
                    key={item.src}
                  >
                    <div
                      className="page-header"
                      style={{ backgroundImage: item.src }}
                    >
                      <div className="filter" />
                      <div className="content-center">{item.content}</div>
                    </div>
                  </CarouselItem>
                  )})
              }
              <a
                className="left carousel-control carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={e => { 
                  e.preventDefault();
                  previous();
                }}
                role="button"
              >
                <i aria-hidden={true} className="nc-icon nc-ctrl-left" />
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="right carousel-control carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  next();
                }}
                role="button"
              >
                <i aria-hidden={true} className="nc-icon nc-ctrl-right" />
                <span className="sr-only">Next</span>
              </a>
          </Carousel>
        </div>
      </div>
  )
}
export default HeaderProyecto